export default function Subscribe(params) {
    
    return (
        <section className="subscribe">
           
            <h2 className="sr-only">Социальные сети</h2>
            <div className="subscribe__wrapper">
                <ul className="subscribe__list subscribe__list_left">
                    <li className="subscribe__item">
                        <picture>
                            <source
                                src="img/blocks/subscribe/photo-15.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/blocks/subscribe/photo-15.jpg"
                                alt="Танец на свадьбе в тяжелом дыму"
                                className="subscribe__item-img"
                            />
                        </picture>
                    </li>
                    <li className="subscribe__item">
                        <picture>
                            <source
                                srcSet= "img/blocks/subscribe/photo-10.jpg"
                                type="image/webp"
                            />
                            <img
                                src={process.env.PUBLIC_URL + "img/blocks/subscribe/photo-10.jpg"}
                                alt="Целующиеся молодожены держат в руках бенгальские огни"
                                className="subscribe__item-img"
                            />
                        </picture>
                    </li>
                    <li className="subscribe__item">
                        <picture>
                            <source
                                srcSet="img/blocks/subscribe/photo-11.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/blocks/subscribe/photo-11.jpg"
                                alt="Целующиеся молодожены и горящее сердце"
                                className="subscribe__item-img"
                            />
                        </picture>
                    </li>
                    <li className="subscribe__item">
                        <picture>
                            <source
                                srcSet="imgwebp/blocks/subscribe/photo-4.webp"
                                type="image/webp"
                            />
                            <img
                                src="img/blocks/subscribe/photo-4.jpg"
                                alt="Танец на свадьбе в тяжелом дыму"
                                className="subscribe__item-img"
                            />
                        </picture>
                    </li>
                </ul>
                <div className="subscribe__text-wrapper">
                    <span className="subscribe__text">
                        Подписывайся на{" "}
                        <a
                            href="https://vk.com/salute_ulyanovsk"
                            rel="noopener"
                            target="_blank"
                            className="subscribe__link"
                        >
                            salute_ulyanovsk
                        </a>
                    </span>
                </div>
                <ul className="subscribe__list subscribe__list_right">
                    <li className="subscribe__item">
                        <picture>
                            <source
                                srcSet="imgwebp/blocks/subscribe/photo-5.webp"
                                type="image/webp"
                            />
                            <img
                                src="img/blocks/subscribe/photo-5.jpg"
                                alt="Молодожены наблюдают за салютом"
                                className="subscribe__item-img"
                            />
                        </picture>
                    </li>
                    <li className="subscribe__item">
                        <picture>
                            <source
                                srcSet="img/blocks/subscribe/photo-12.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/blocks/subscribe/photo-12.jpg"
                                alt="Салют в небе"
                                className="subscribe__item-img"
                            />
                        </picture>
                    </li>
                    <li className="subscribe__item">
                        <picture>
                            <source
                                srcSet="img/blocks/subscribe/photo-13.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/blocks/subscribe/photo-13.jpg"
                                alt="Молодожены целуются под пиротехническое шоу"
                                className="subscribe__item-img"
                            />
                        </picture>
                    </li>
                    <li className="subscribe__item">
                        <picture>
                            <source
                                srcSet="img/blocks/subscribe/photo-14.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/blocks/subscribe/photo-14.jpg"
                                alt="Молодожены наблюдают за салютом"
                                className="subscribe__item-img"
                            />
                        </picture>
                    </li>
                </ul>
            </div>
          
        </section>
    );
}
