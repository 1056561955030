import axios from "axios";
import { useEffect, useState } from "react";
import "../css/portfolio.css";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";
export default function Portfolio(params) {
    const [img, setimg] = useState(null);
    const [webp, setwebp] = useState(null);
    const [count, setcount] = useState(10);
    const [arr, setarr] = useState(null);

    useEffect(() => {
        axios
            .get("/date/server.php")
            .then((response) => {
                setimg(response.data[0]);
                setwebp(response.data[1]);
                   
                    
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    useEffect(() => { if (img!==null) {
        sortMass({img,webp});
    }}, [img,count])
    useEffect(() => {
     console.log("www");
    }, [arr])
    
    function sortMass({img,webp}) {
        let mas =[];
        for (let i = 0; i < count; i++) {
            mas[i]={"img":img[i],"webp":webp[i]}
        }
        setarr(mas);
    }
    if (img === null) {
        return "ddd";
    }
  
   
    return (
        <main className="main">
            <nav
                className="breadcrumbs"
                aria-label="Хлебные крошки"
            >
                <ol
                    className="breadcrumbs__list"
                    itemScope
                    itemType="https://schema.org/BreadcrumbList"
                >
                    <li
                        className="breadcrumbs__item"
                        itemProp="itemListElement"
                        itemScope
                        itemType="https://schema.org/ListItem"
                    >
                        <a
                            href="/"
                            className="breadcrumbs__link"
                            itemProp="item"
                        >
                            <span itemProp="name">
                                Главная
                            </span>
                            <meta
                                itemProp="position"
                                content="1"
                            />
                        </a>
                    </li>
                    <li
                        className="breadcrumbs__item"
                        itemProp="itemListElement"
                        itemScope
                        itemType="https://schema.org/ListItem"
                    >
                        <p
                            className="breadcrumbs__current"
                            aria-current="page"
                            itemProp="item"
                        >
                            <span itemProp="name">
                                Портфолио
                            </span>
                        </p>
                        <meta
                            itemProp="position"
                            content="2"
                        />
                        <p></p>
                    </li>
                </ol>
            </nav>

            <section className="portfolio section-first">
                <h1 className="portfolio__title section-title">
                    Портфолио
                </h1>
                <ul className="portfolio__list">
                    {arr && arr.map((e, i) => (
                       <li
                       key={i}
                       className="portfolio__item"
                   >
                       <a
                           className="portfolio__item-link"
                           href={e.webp}
                           data-fslightbox="portfolio"
                       >
                           <picture>
                               <source
                                   srcSet={e.webp}
                                   type="image/webp"
                               />
                               <img
                                   src={e.img}
                                   alt="Фото 1"
                                   className="portfolio__item-img"
                               />
                           </picture>
                       </a>
                   </li>
                    ))}
                </ul>
                {count+20 < img.length &&
                <button className="header__catalogue-link portfolio__more" onClick={()=>setcount(count+20)} data-animate-fill="true" style={{"--x": "99.5px", "--y": "21px"}}><span>Показать ещё</span></button>
                }
            </section>
            <Stages />
                <Subscribe />
        </main>
    );
}
