import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";

export function SparklesAndCrackers(params) {
    return (
        <>
            <main className="main">
                <Description
                    name={"Бенгальские огни и хлопушки"}
                    text={
                        "Большие бенгальские огни на любом празднике – способ вовлечь всех гостей и сделать яркие кадры. Большие бенгальские огни в Ульяновске покупают на свадьбу для первого танца молодых, для создания радостной и в то же время романтичной атмосферы; украшают момент подачи торта; создают эффектный световой коридор для молодожёнов. Горящие искры огней помогают ощутить сопричастность событию. Мы предлагаем широкий выбор продукции от разных производителей. Выберите свой цвет. Зажечь бенгальские огни в большом Ульяновске – это классно!"
                    }
                    gall={
                        <ul className="description__gallery">
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/sparkles-and-crackers/gallery/1.jpg"
                                    data-fslightbox="sparkles-and-crackers"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/sparkles-and-crackers/gallery/1.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/sparkles-and-crackers/gallery/2.jpg"
                                    data-fslightbox="sparkles-and-crackers"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/sparkles-and-crackers/gallery/2.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/sparkles-and-crackers/gallery/3.jpg"
                                    data-fslightbox="sparkles-and-crackers"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/sparkles-and-crackers/gallery/3.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/sparkles-and-crackers/gallery/4.jpg"
                                    data-fslightbox="sparkles-and-crackers"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/sparkles-and-crackers/gallery/4.jpg"
                                    />
                                </a>
                            </li>
                        </ul>
                    }
                    fease={
                        <>
                            <source
                                srcSet="imgwebp/pages/sparkles-and-crackers/bg.webp"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/sparkles-and-crackers/bg.jpg"
                                alt="Профессиональные салюты"
                                className="description__img"
                            />
                        </>
                    }
                />
                <section className="content section">
                    <div className="content__wrapper">
                        <div className="content__info">
                            <h2 className="section-title">
                                За&shy;ка&shy;зать или
                                ку&shy;пить
                                боль&shy;шие бен&shy;галь&shy;ские огни
                            </h2>
                            <div className="content__descr">
                                <p className="content__descr-text">
                                    Вы можете заказать или
                                    купить большие
                                    бенгальские огни у нас
                                    по доступным ценам. Они
                                    бывают одноцветными и
                                    многоцветными. Можно
                                    разделить гостей на
                                    группы со своим «цветом»
                                    и построить огненные
                                    змейки. Покупая большие
                                    бенгальские огни, Вы
                                    покупаете целый сноп
                                    искр, разлетающихся с
                                    лёгким шорохом. Это
                                    красиво и дарит
                                    незабываемое тепло.
                                </p>
                            </div>
                            <button
                                className="content__order final__button"
                                data-animate-fill
                                data-fos
                            >
                                <span>Забронировать</span>
                            </button>
                        </div>
                        <picture>
                            <source
                                srcSet="imgwebp/pages/sparkles-and-crackers/content/sparkles.webp"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/sparkles-and-crackers/content/sparkles.jpg"
                                alt="Бенгальские огни и хлопушки"
                                className="content__img"
                            />
                        </picture>
                    </div>
                </section>
                <section className="content section">
                    <div className="content__wrapper">
                        <div className="content__info content__info_second">
                            <h2 className="section-title">
                                За&shy;ка&shy;зать или
                                ку&shy;пить
                                хло&shy;пуш&shy;ки
                            </h2>
                            <div className="content__descr">
                                <p className="content__descr-text">
                                    В наших магазинах Вы
                                    можете купить хлопушки,
                                    которые, совместно с
                                    бенгальскими огнями,
                                    могут использоваться
                                    внутри помещения. Издав
                                    громкий звук, они
                                    рассыпают вокруг
                                    разноцветное конфетти,
                                    которое долго падает
                                    вниз, напоминая снег.
                                    Купив хлопушки на
                                    детский праздник, Вы
                                    ничем не рискуете –
                                    хлопушки - один из самых
                                    безопасных товаров
                                    нашего широкого
                                    ассортимента.
                                </p>
                            </div>
                            <button
                                className="content__order final__button"
                                data-animate-fill
                                data-fos
                            >
                                <span>Забронировать</span>
                            </button>
                        </div>
                        <picture className="content__picture content__picture_first">
                            <source
                                srcSet="imgwebp/pages/sparkles-and-crackers/content/crackers.webp"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/sparkles-and-crackers/content/crackers.jpg"
                                alt="Бенгальские огни и хлопушки"
                                className="content__img"
                            />
                        </picture>
                    </div>
                </section>
                <Stages />
                <Subscribe />
            </main>
        </>
    );
}
