import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";

export function ColoredSmoke(params) {
    return (
        <>
            <main className="main">
                <Description
                    name={"Цветной дым"}
                    text={
                        "Для крутых фотосессий, эффектных вечеринок и креативных идей! Хочешь новые, яркие фото? Цветной дым добавит твоим фотографиям сочности и креатива! Кто останется равнодушным, «плавая» в контрастных волнах разноцветного воздуха? Вы можете найти свой уникальный цвет, подобрав цветной дым в тон Вашей одежде. Вы можете резко сменить его насыщенность и даже заменить цвет дымом другого цвета. Возможно практически всё! А еще это – очень весело и интересно!"
                    }
                    gall={
                        <ul className="description__gallery">
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/colored-smoke/gallery/1.jpg"
                                    data-fslightbox="colored-smoke"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/colored-smoke/gallery/1.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/colored-smoke/gallery/3.jpg"
                                    data-fslightbox="colored-smoke"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/colored-smoke/gallery/3.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/colored-smoke/gallery/4.jpg"
                                    data-fslightbox="colored-smoke"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/colored-smoke/gallery/4.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/colored-smoke/gallery/2.jpg"
                                    data-fslightbox="colored-smoke"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/colored-smoke/gallery/2.jpg"
                                    />
                                </a>
                            </li>
                        </ul>
                    }
                    fease={
                        <>
                            <source
                                srcSet="imgwebp/pages/colored-smoke/bg.webp"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/colored-smoke/bg.jpg"
                                alt="Тяжелый дым"
                                className="description__img"
                            />
                        </>
                    }
                />
                <section className="content section">
                    <div className="content__wrapper">
                        <div className="content__info">
                            <h2 className="section-title">
                                За&shy;ка&shy;зать
                                цвет&shy;ной дым
                            </h2>
                            <div className="content__descr">
                                <p className="content__descr-text">
                                    Каждый найдёт свой цвет:
                                </p>
                                <ul className="content__list">
                                    <li className="content__item">
                                        <strong>
                                            Фиолетовый:
                                        </strong>{" "}
                                        модный и популярный.
                                        Один из самых
                                        мистических и
                                        загадочных.
                                        Получаются целые
                                        картины из цветного
                                        дыма!
                                    </li>
                                    <li className="content__item">
                                        <strong>
                                            Чёрный:
                                        </strong>{" "}
                                        стильный. Прекрасно
                                        подходит для
                                        брутальных мужских
                                        снимков и смелых
                                        женских образов
                                    </li>
                                    <li className="content__item">
                                        <strong>
                                            Белый:
                                        </strong>{" "}
                                        универсальный для
                                        тематических
                                        фотосессий.
                                        Используют для
                                        акцента на
                                        персонажа, создания
                                        контраста, подобно
                                        эффекту тумана или
                                        дыма от костра.
                                    </li>
                                    <li className="content__item">
                                        <strong>
                                            Красный:
                                        </strong>{" "}
                                        символ влюблённых,
                                        гамма страсти –
                                        самый контрастный
                                        оттенок. Создаёт
                                        эффекты от самых
                                        романтичных до самых
                                        страстных, служит
                                        основой неординарных
                                        и ярких фотоснимков.
                                    </li>
                                    <li className="content__item">
                                        <strong>
                                            Розовый:
                                        </strong>{" "}
                                        милый и нежный.
                                        Прекрасно дополняет
                                        снимки маленькой
                                        леди, романтический
                                        фотоальбом,
                                        сказочный женский
                                        образ, подходит для
                                        фото с девичника.
                                    </li>
                                    <li className="content__item">
                                        <strong>
                                            Жёлтый:
                                        </strong>{" "}
                                        один из самых
                                        позитивных.
                                        Ассоциируется с
                                        солнцем, всегда
                                        выгладит ярко и
                                        красочно. Идеально
                                        подходит для детских
                                        фотосессий.
                                    </li>
                                    <li className="content__item">
                                        <strong>
                                            Оранжевый:
                                        </strong>{" "}
                                        тёплый и насыщенный.
                                        Он одним своим видом
                                        заряжает позитивом.
                                        Использовать нужно в
                                        ясную погоду, на
                                        тёмных фото выглядит
                                        коричневым.
                                    </li>
                                    <li className="content__item">
                                        <strong>
                                            Синий / Голубой:
                                        </strong>{" "}
                                        одно из классических
                                        свадебных сочетаний.
                                        Создаёт образ
                                        природной гармонии.
                                    </li>
                                    <li className="content__item">
                                        <strong>
                                            Бирюзовый:
                                        </strong>{" "}
                                        появился недавно,
                                        совершенно нереален
                                        при естественном
                                        освещении. Отличный
                                        выбор для смелых и
                                        дерзких.
                                    </li>
                                    <li className="content__item">
                                        <strong>
                                            Зелёный:
                                        </strong>{" "}
                                        Абсолютно
                                        нейтральный со своей
                                        сказочностью.
                                        Подходит для детских
                                        снимков, для
                                        тематических сессий
                                        на природе.
                                    </li>
                                </ul>
                                <p className="content__descr-text">
                                    Время дымления и цена:
                                    30 сек. – от 250Р, 60
                                    сек. – от 470Р.
                                </p>
                                <p className="content__descr-text">
                                    Экспериментируй! Пробуй
                                    разное сочетание
                                    цветного дыма!
                                </p>
                            </div>
                            <button
                                className="content__order final__button"
                                data-animate-fill
                                data-fos
                            >
                                <span>Забронировать</span>
                            </button>
                        </div>
                        <picture>
                            <source
                                srcSet="imgwebp/pages/colored-smoke/content/order.webp"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/colored-smoke/content/order.jpg"
                                alt="Тяжелый дым"
                                className="content__img"
                            />
                        </picture>
                    </div>
                </section>
                <Stages />
                <Subscribe />
            </main>
        </>
    );
}
