import Description from "../bloc/description";
import Stages from "../bloc/stages";
import Subscribe from "../bloc/subscribe";

export function FieryFigures(params) {
    return (
        <>
            <main className="main">
                <Description
                    name={"Горящие фигуры и надписи"}
                    text={
                        "Горящие цифры, буквы, надписи - это пиротехническая фигура. \n\n По её контуру расположены контурные свечи зажигаемые единовременно с помощью пульта.\n\n Фигуры могут состоять из поздравительных надписей, букв, цифр или сердец, выглядеть в виде логотипа компании или любых других символов по вашему желанию.\n\n Продолжительность работы 45-60 сек."
                    }
                    gall={
                        <ul className="description__gallery">
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/fiery-hearts/gallery/1.jpg"
                                    data-fslightbox="fiery-hearts"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/fiery-hearts/gallery/1.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/fiery-hearts/gallery/2.jpg"
                                    data-fslightbox="fiery-hearts"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/fiery-hearts/gallery/2.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/fiery-hearts/gallery/3.jpg"
                                    data-fslightbox="fiery-hearts"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/fiery-hearts/gallery/3.jpg"
                                    />
                                </a>
                            </li>
                            <li className="description__gallery-item">
                                <a
                                    className="description__gallery-link"
                                    href="img/pages/fiery-hearts/gallery/4.jpg"
                                    data-fslightbox="fiery-hearts"
                                >
                                    <img
                                        className="description__gallery-image"
                                        src="img/pages/fiery-hearts/gallery/4.jpg"
                                    />
                                </a>
                            </li>
                        </ul>
                    }
                    fease={
                        <>
                            <source
                                srcSet="img/pages/fiery-hearts/bg.jpg"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/fiery-hearts/bg.jpg"
                                alt="Огненные фонтаны"
                                className="description__img"
                            />
                        </>
                    }
                />
                <section className="content section">
                    <div className="content__wrapper">
                        <div className="content__info">
                            <h2 className="section-title">
                                За&shy;ка&shy;зать
                                го&shy;ря&shy;щую
                                фи&shy;гу&shy;ру
                            </h2>
                            <div className="content__descr">
                                <p className="content__descr-text">
                                    Заказать горящие фигуры
                                    можно у нас. В этом
                                    случае заказ должен быть
                                    оформлен за несколько
                                    дней до мероприятия –
                                    экспертам необходимо
                                    выехать и оценить
                                    площадку, подготовить
                                    необходимые документы.
                                    Заказав горящие фигуры,
                                    не забудьте их
                                    зафиксировать с помощью
                                    профессионального
                                    фотографа, так как
                                    искусство огненного
                                    мастера – недолговечная
                                    и быстро таящая
                                    скульптура. Фотография
                                    поможет её запечатлеть и
                                    запомнить.
                                </p>
                            </div>
                            <button
                                className="content__order final__button"
                                data-animate-fill
                                data-fos
                            >
                                <span>Забронировать</span>
                            </button>
                        </div>
                        <picture>
                            <source
                                srcSet="imgwebp/pages/fiery-hearts/content/order.webp"
                                type="image/webp"
                            />
                            <img
                                src="img/pages/fiery-hearts/content/order.jpg"
                                alt="Огненные сердца"
                                className="content__img"
                            />
                        </picture>
                    </div>
                </section>
                <Stages />
                <Subscribe />
            </main>
        </>
    );
}
