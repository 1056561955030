import { NavLink } from "react-router-dom";
import "../css/main.css";
import ModalMain from "../modul/modalMainPopup";

export default function Main(params) {
    return (
        <main className="main s-main">
            <h1 className="sr-only">Салют73 — Ульяновск</h1>
            <div className="swiper">
                <ul className="swiper-wrapper">
                    <li
                        className="swiper-slide swiper-no-swiping tabs__item active"
                        id="tab_1"
                    >
                        <section className="final">
                            <p className="sr-only">
                                Пиротехническое шоу и Салюты
                                Ульяновска
                            </p>
                            <div className="final__title-wrapper">
                                <div className="final__title">
                                    <h1 className="animated animated--mr">
                                        Пиротехническое шоу
                                        и Салюты Ульяновска
                                    </h1>
                                    <p className="final__descr">
                                        Заказать
                                        пиротехническое шоу
                                        в Ульяновске от
                                        компании «Салют
                                        Ульяновск» -
                                        отличное решение к
                                        Вашему празднику.
                                        Это комплекс
                                        огненных эффектов,
                                        составляющих целый
                                        сюжет, выстроенный
                                        по Вашему сценарию.
                                        Шоу из
                                        пиротехнических
                                        салютов и огненных
                                        брызг - незабываемо!
                                    </p>
                                </div>
                                <button
                                    className="final__button description__more animated"
                                    data-animate-fill
                                >
                                    <span>
                                        Отправить заявку
                                    </span>
                                </button>{" "}
                                <a
                                    href="https://shop-salut.ru"
                                    className="final__button final__button--main final__button--hidden animated"
                                    data-animate-fill
                                >
                                    <span>
                                        каталог бытовой
                                        пиротехники
                                    </span>
                                </a>
                            </div>
                            <div className="final__wrapper">
                                <li className="final__item final__item_wedding final__item--special">
                                    <a
                                        href="https://shop-salut.ru"
                                        className="final__item-link"
                                    >
                                        <picture>
                                            <img
                                                src="img/pages/main/shopSalytBg.jpg"
                                                alt="Гендер пати"
                                                className=""
                                            />
                                        </picture>
                                        <button
                                            className="final__button_nopopup final__button--main animated"
                                            style={{
                                                position:
                                                    "absolute",
                                                zIndex: "2",
                                                bottom: "-1px",
                                                borderRadius:
                                                    "0 0 5px 5px",
                                            }}
                                            data-animate-fill
                                        >
                                            <span>
                                                интернет-магазин
                                                <br />
                                                бытовой
                                                пиротехники
                                            </span>{" "}
                                            <svg
                                                fill="none"
                                                height="50"
                                                viewBox="0 0 50 30"
                                                width="30"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    width: "70px",
                                                }}
                                            >
                                                <path
                                                    d="M1.39999 1.70001H6.60001"
                                                    stroke="#11255f"
                                                    strokeLinecap="round"
                                                    strokeMiterlimit="10"
                                                    strokeWidth="2"
                                                ></path>
                                                <path
                                                    d="M6.60001 1.70001L11 18.9"
                                                    stroke="#11255f"
                                                    strokeLinecap="round"
                                                    strokeMiterlimit="10"
                                                    strokeWidth="2"
                                                ></path>
                                                <path
                                                    d="M11.8 18.9H28.3"
                                                    stroke="#11255f"
                                                    strokeLinecap="round"
                                                    strokeMiterlimit="10"
                                                    strokeWidth="2"
                                                ></path>
                                                <path
                                                    d="M13.8 25.7C15.4569 25.7 16.8 24.3569 16.8 22.7C16.8 21.0432 15.4569 19.7 13.8 19.7C12.1431 19.7 10.8 21.0432 10.8 22.7C10.8 24.3569 12.1431 25.7 13.8 25.7Z"
                                                    stroke="#11255f"
                                                    strokeLinecap="round"
                                                    strokeMiterlimit="10"
                                                    strokeWidth="2"
                                                ></path>
                                                <path
                                                    d="M25.3 25.7C26.9568 25.7 28.3 24.3569 28.3 22.7C28.3 21.0432 26.9568 19.7 25.3 19.7C23.6431 19.7 22.3 21.0432 22.3 22.7C22.3 24.3569 23.6431 25.7 25.3 25.7Z"
                                                    stroke="#11255f"
                                                    strokeLinecap="round"
                                                    strokeMiterlimit="10"
                                                    strokeWidth="2"
                                                ></path>
                                                <path
                                                    d="M25.7 14.6H11.3C10.7 14.6 10.1 14.2 10 13.6L8.1 6.90001C7.9 6.00001 8.49999 5.20001 9.39999 5.20001H27.5C28.4 5.20001 29.1 6.10001 28.8 6.90001L26.9 13.6C26.9 14.2 26.4 14.6 25.7 14.6Z"
                                                    stroke="#11255f"
                                                    strokeLinecap="round"
                                                    strokeMiterlimit="10"
                                                    strokeWidth="2"
                                                ></path>
                                            </svg>
                                        </button>
                                    </a>
                                </li>
                                <ul className="final__list animated">
                                    <li className="final__item final__item_fire">
                                        <NavLink
                                            to="/Gender_party_in_Ulyanovsk"
                                            className="final__item-link"
                                        >
                                            <picture>
                                                <img
                                                    src="img/pages/main/gender.jpg"
                                                    alt="Гендер пати"
                                                    className="final__item-img"
                                                />
                                            </picture>
                                            <span className="final__item-text-wrapper">
                                                <span className="final__item-text">
                                                    Гендер
                                                    пати
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="final__item final__item_heart">
                                        <NavLink
                                            to="/fiery-figures"
                                            className="final__item-link"
                                        >
                                            <picture>
                                                <img
                                                    src="img/pages/main/new/3.jpg"
                                                    alt="Горящие сердца и надписи"
                                                    className="final__item-img"
                                                />
                                            </picture>
                                            <span className="final__item-text-wrapper">
                                                <span className="final__item-text">
                                                    Горящие
                                                    фигуры и
                                                    надписи
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="final__item final__item_sparkles">
                                        <NavLink
                                            to="/sparkles-and-crackers"
                                            className="final__item-link"
                                        >
                                            <picture>
                                                <img
                                                    src="img/pages/main/new/4.jpg"
                                                    alt="Бенгальские огни"
                                                    className="final__item-img"
                                                />
                                            </picture>
                                            <span className="final__item-text-wrapper">
                                                <span className="final__item-text">
                                                    Бенгальские
                                                    огни
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="final__item final__item_pyrotechnic">
                                        <NavLink
                                            to="/pyrotechnic-show"
                                            className="final__item-link"
                                        >
                                            <picture>
                                                <img
                                                    src="img/pages/main/new/5.jpg"
                                                    alt="Пиротехническое шоу"
                                                    className="final__item-img"
                                                />
                                            </picture>
                                            <span className="final__item-text-wrapper">
                                                <span className="final__item-text">
                                                    Пиротехническое
                                                    шоу
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li
                                        className="final__item final__item_wedding final__item_weddding final__item_special2"
                                        style={{
                                            padding: "0",
                                            borderRadius:
                                                "7px",
                                        }}
                                    >
                                        <NavLink
                                            to="/wedding-firework"
                                            className="final__item-link"
                                            target="_blank"
                                        >
                                            <span className="final__item-text-wrapper">
                                                <span className="final__item-text">
                                                    Свадебный
                                                    фейерверк
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li className="final__item final__item_smoke">
                                        <NavLink
                                            to="/heavy-smoke"
                                            className="final__item-link"
                                        >
                                            <picture>
                                                <img
                                                    src="img/pages/main/new/6.jpg"
                                                    alt="Тяжелый дым"
                                                    className="final__item-img"
                                                />
                                            </picture>
                                            <span className="final__item-text-wrapper">
                                                <span className="final__item-text">
                                                    Тяжелый
                                                    дым
                                                </span>
                                            </span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </section>
                    </li>
                </ul>
            </div>
           {/* <ModalMain/> */}
        </main>
    );
}
